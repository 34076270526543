import { imageUrlAdapter } from '@/adapters/constructor/utils/image';

const staffAdapter = (data) => {
  return {
    id: data.id,
    text: data.text,
    title: data.title,
    image: imageUrlAdapter(data.banner?.image),
  };
};

export default ({ data }) => {
  return {
    staff: data.staff?.map(staffAdapter),
  };
};
